import validateEN from 'vee-validate/dist/locale/en.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    nationalRegisterNumber: 'National Register Number',
    ascending: 'ascending',
    descending: 'descending',
    eur: '€',
    share: 'share | shares',
    myInterestRate: 'My interest rate',
    investment: 'investment | investments',
    fund: 'fund | funds',
    invest: 'invest',
    investIn: 'invest in this fund',
    investMore: 'invest more',
    investNow: 'invest now',
    payment: 'payment | payments',
    totalReceived: 'Total received',
    total: 'total',
    name: 'name | names',
    surname: 'Last name',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    password: 'password | passwords',
    save: 'save',
    confirm: 'confirm',
    reserve: 'reserve',
    activate: 'activate',
    iDIN: 'iDIN',
    iDeal: 'iDeal',
    dashboard: 'dashboard',
    myDashboard: 'My dashboard',
    back: 'back',
    sold: 'sold',
    participation: 'minimum investment',
    available: 'available',
    download: 'download | downloads',
    process: 'process | processes',
    processing: 'processing',
    success: 'success',
    paid: 'paid',
    cancel: 'cancel',
    telephone: 'telephone',
    companyName: 'company name',
    dateOfBirth: 'date of birth',
    bankAccount: 'bank account',
    sendDataChangeRequest: 'Send data change request',
    dataChangeRequestPending: 'Data change request is pending',
    dataChangeRequestValue: 'Requested value',
    iban: 'IBAN',
    city: 'city',
    country: 'country',
    address: 'address',
    houseNumber: 'house number',
    number: 'number',
    postalCode: 'postal code',
    welcome: 'welcome Mr./Mrs.',
    availability: 'availability',
    contact: 'contact',
    or: 'or',
    to: 'to',
    support: 'support',
    message: 'message',
    login: 'login',
    register: 'register',
    reset: 'reset',
    resetPass: 'reset password',
    setPass: 'set password',
    revertSecondFactor: 'revert 2-factor authentication',
    nationality: 'nationality',
    realEstate: 'real estate index',
    aex: 'aex',
    vs: 'vs',
    warning: 'warning',
    location: 'location | locations',
    floorplan: 'floorplan',
    benefit: 'benefit | benefits',
    company: 'company | companies',
    setting: 'setting | settings',
    logout: 'logout',
    optional: 'optional',
    source: 'source',
    portfolio: 'portfolio',
    fundsLink: 'fund | funds',
    knowledge: 'knowledge base',
    glossary: 'glossary',
    loadMore: 'show more funds',
    loadLess: 'show less funds',
    verified: 'account verified',
    year: 'year | years',
    yearInitials: 'y',
    perYear: 'per year',
    perYearShort: 'p/yr',
    my: 'my',
    details: 'details',
    na: 'n.a.',
    send: 'send',
    bond: 'bond | bonds',
    date: 'date',
    month: 'month | months',
    yearly: 'yearly',
    monthly: 'monthly',
    search: 'search',
    next: 'next',
    prev: 'prev',
    fundCompleted: 'completed',
    incorrectPassword: 'incorrect password',
    autoLogOut: 'You have been automatically logged out due to inactivity.',
    accountDisabled: 'Your account has been disabled.',
    contactSupport: 'Please contact {email} or call {phone} for support.',
    supportQuestion: 'How can we help you? Ask your question here:',
    websiteMaintenance: 'Website under maintenance.',
    paidOnline: 'Paid online',
    paidOffline: 'Paid offline',
    dutch: 'Dutch',
    english: 'English',
    gender: 'gender',
    male: 'male',
    female: 'female',
    emission: 'Emission costs',
    decline: 'Decline',
    accept: 'Accept',
    disabled: 'This option cannot be changed',
    loginToSeeInfo: 'Login to see the information',
    rentalIncome: 'Rental income',
    addedValue: 'Added value',
    more: 'more',
    moreInfo: 'more info',
    referralCode: 'referral code',
    period: 'period',
    costs: 'costs',
    taxes: 'taxes',
    gross: 'gross',
    net: 'net',
    referAFriendBenefits: 'Get 6 months of double rental income.',
  },
  validations: {
    ...validateEN.messages,
    alpha_num: 'This field may only contain alpha-numeric characters',
    alpha_spaces: 'This field may only contain alphabetical characters and spaces',
    confirmed: 'This field confirmation does not match',
    email: 'This field must be a valid email',
    integer: 'This field must be a integer number',
    max: 'This field may not be greater than {length} characters',
    min: 'This field must be at least {length} characters',
    ext: 'File type must be of type {types}',
    required: 'This field is required',
    multiplier: {
      payment: 'The amount to participate has to be a multiplier of the price per share ({number}€).',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      accessibleAndUnderstandable: 'Easy and accessible investments in real estate',
      applyNowInvestor: 'Register now and become a real estate investor!',
      exploreProperties: 'Explore real estate',
      registerNow: 'Register now',
      explorePropertiesBtn: 'Explore funds',
    },
    accessible: {
      accessibleAndTransparent: 'Accessible and transparent',
      weMakeItVeryEasy: 'It is Bloqhouse\'s mission to make investing in real estate transparent and accessible',
      hasManyYears: 'Shared ownership in real estate makes it possible to invest without having to put time and effort into management',
      youCanStartBuying: 'After choosing one of the objects on the platform you can invest within minutes',
      onceTheSaleIsCompleted: `Your identity will be verified via iDIN and shares are for sale staring from € 100 to € 100,000 via iDEAL
        (shares above € 100,000 are via SEPA bank transfer)`,
      startBuying: 'Buy real estate shares starting from € 100',
      receivedMonth: 'Receive interest rate per quarter in Euros',
      tradeYourShares: 'Sell your shares via the trading platform (launch: end of 2019)',
    },
    fund: {
      emissionPrice: 'Price per Share',
      totalFundSize: 'Total fund size',
      seeDetails: 'See details',
    },
    counters: {
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      dividendsPerYear: 'interest rate per year',
      occupancyRate: 'Occupancy rate',
    },
    benefits: {
      exploreProperties: 'Explore properties',
      tab1: 'Invest directly',
      tab1Title: 'Invest directly',
      tab1Text: `Choose an object and decide how much you want to invest. With an average of one extra fund per two months,
      Bloqhouse strives to facilitate the largest real estate offer in the Benelux. You can put together your own portfolio
      that meets your criteria. In addition, it is easy to diversify with different types of real estate. There is a
      one-time cost of 2% connected to an investment`,
      tab2: 'Receive interest rate ',
      tab2Title: 'Receive interest rate ',
      tab2Text: `You receive interest rate on your investment every quarter.
        The interest rate consists of the rental income minus the management costs of the property`,
      tab3: 'Check your return',
      tab3Title: 'Check your return',
      tab3Text: `Check the performance of your investments on your dashboard. All of our properties are appraised every
        6 months by an RICS certified appraiser. This allows you to estimate the value fluctuation of the purchased real estate.
        The interest rate income is automatically included in the dashboard`,
      tab4: 'Liquidity',
      tab4Title: 'Liquidity',
      tab4Text1: `It’s possible to offer shares at any time via our trading platform. Selling is simple. Place your shares
        up for sale on the trading platform. Or alternatively: wait for the 5-yearly sale of the real estate,
        whereby investors will receive the potential surplus value of the property. The average time to sell a share is 4 days`,
      tab4Text2: `Depending on the chosen price, it may take longer or shorter. Please note: during the introduction period of
        the trading platform, the trading volume will be lower than average, which may result in a longer sale time`,
      tab5: 'Overhead',
      tab5Title: 'Overhead',
      tab5Text: `Investing in real estate is time-consuming and complex. Bloqhouse changes that. Paperwork, maintenance,
        lawyers and defaulters are now a thing of the past. Bloqhouse and the affiliated real estate funds arrange that for you,
        with many decades of market experience. This way you can profit from rental income and any potential value increases in real
        estate, without any hassle!`,
      tab5TextPoint1: 'Search for investments',
      tab5TextPoint2: 'Negotiation process',
      tab5TextPoint3: 'Paperwork and lawyers',
      tab5TextPoint4: 'Search for tenants',
      tab5TextPoint5: 'Maintenance and repairs',
      tab5TextPoint6: 'Valuations',
      tab5TextPoint7: 'Sale process',
      tab6: 'Our policy',
      tab6Title: 'Our policy',
      tab6Satisfied: 'Satisfied tenants:',
      tab6Text1: `Bloqhouse believes that satisfied tenants are the key to future success. Our real estate funds demand fair
        rents and maintain the property thoroughly, so that tenants stay for longer periods of time. This means stable rental
        income for you`,
      tab6SmartPurchases: 'Smart purchases:',
      tab6Text2: `Bloqhouse works together with large real estate funds in the Netherlands. As a result, we find
        the best purchases, which are usually and often owned by large companies or wealthy individuals`,
    },
    investing: {
      investingMadeSimple: 'Investing made simple',
      selectTheFund: 'Select the fund in which you want to invest',
      registerAndIdentify: 'Register and identify via iDIN',
      buyYourShares: 'Buy your Shares via iDeal',
      receiveMonth: 'Receive interest rate per quarter',
    },
    stock: {
      amsterdamStockExchange: 'Amsterdam Exchange Index',
      pastPerformance: 'Past performance is no guarantee for future results',
    },
    safeGuards: {
      investmentSafeguards: 'Investment safeguards',
      weTakeOurResponsabilities: 'We have taken responsibility to provide you with trust and ease-of-mind while investing',
      yourInvestmentsAreRingFenced: 'Your investments are protected',
      andAreSeparated: 'They are protected and separated from the assets and liabilities of Bloqhouse',
      compensationScheme: 'Compensation Scheme',
      protectsTheFunds: 'Your bank balance on the platform is insured via a Third-party funds foundation',
      financialConductAuthority: 'Dutch Financial Markets Authority',
      isTheBody: 'The platform is regulated by the Dutch Financial Markets Authority',
    },
    expectations: {
      assetsUnderManagement: 'Assets under management',
      capitalReturnedToInvestors: 'Capital returned to investors',
      rentalIncome: 'Rental income returned to investors',
    },
    faq: {
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the fund, the value of the property is divided pro rata among the shareholders at that time`,
      whenIsItSold: 'When is the real estate project sold / fund liquidated?',
      theAverage: 'The average duration of a fund is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the funds of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my shares?',
      sharesCanBe: 'Shares can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the fund is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the fund and real estate management?',
      thatIsBeingCared: 'The fund managers do this',
      questionsAnswers: 'Questions & Answers',
    },
    risks: {
      investmentRisks: 'Please note',
      generalRisksAssociated: `There are general risks associated with investing in property funds: the market risk, risk with regard to licensing
      requirements, risk of stock interest rate, return risk, financing and interest rate risk, rental and vacancy risk, tax risk, inflation risk and
        risks associated with the manager`,
      theMainRisks: 'Bloqhouse is not obliged to purchase shares',
      aDetailedDescription: 'A detailed description of the risks can be found in the prospectus of the related fund',
    },
    cookies: {
      allowUs: 'Do you allow us to store {cookiesLink}?',
      yourInfoAnalytics: 'Your information will be used for analytics and website optimisation',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Forgot password?',
      dontHaveAnAccountYet: 'Don\'t have an account yet?',
      enterYourPassword: 'Enter your password',
      pleaseActivate: 'Please activate your account using the activation link sent to your e-mail',
      userHasRole: 'You tried to log in with an admin account. Please use it in Bloqadmin only.',
      captchaTimeExpired: 'Captcha verification expired',
      tryAgain: 'Please try again',
    },
    register: {
      alreadyHaveAnAccount: 'Already have an account?',
      thankYou: 'Thank you for registering',
      pleaseCheckEmail: 'Please check your e-mail to activate your account',
      itCanTake: 'It can take a few minutes to arrive',
      youCan: 'You can',
      afterActivating: 'after activating your account',
      confirmPassword: 'Confirm password',
    },
    reset: {
      sendResetLink: 'Send reset link',
      rememberYourPassword: 'Remember your password?',
      emailSent: 'If the provided email is registered, you should have received an email',
      pleaseCheckInbox: 'Please check your inbox',
    },
    verification: {
      verifyingSuccess: 'Everything is set, you can login now',
      verifyingError: 'Something went wrong! Register again',
      changeError: 'Something went wrong! Try again',
      changeSuccess: 'Everything is set, you can login',
      passwordLenght: 'Password has to be 10 or longer',
      changeDataRequestConfirmation: 'Are you sure you want to change your <strong>{type}</strong> from <em>{oldValue}</em> to <em>{newValue}<em/>',
      revertSecondFactor: 'You activated second factor authentication, on this page you can revert this action',
      revertSecondFactorSuccess: 'You successfully deactivated 2-factor authentication',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verify',
      dismiss: 'Dismiss',
      notNow: 'Not now',
      pending: 'Your account is being verified',
      pendingBlockchainWhitelist: 'Blockchain wallet whitelisting in progress.',
      pendingDescription: 'You will be notified soon by {fundManager}',
      youCanInvest: 'You can already invest in your first fund',
      youNeedApproval: 'You can only invest after verifying your account',
      upgradeTo: 'You still need to verify your account',
      basicUser: 'You have now only created an account',
    },
    balance: {
      totalBalance: 'My holdings',
    },
    funds: {
      totalValue: 'Fund size',
      myInvestment: 'My investment',
      netDividendYield: 'Interest rate',
      myPortfolio: 'My Portfolio',
      myFunds: 'My funds',
      otherFunds: 'Other funds',
      myFundsPlaceholder: 'All the funds you have invested in will be listed here',
      myFundsStartInvest: 'Invest on your first fund',
      progress: 'Fund progress',
      premium: 'This is a Premium fund, please login to see it',
      premiumLabel: 'Premium fund',
      reservationLabel: 'Pre-registration',
      fullHistory: 'View the full history of your investments',
    },
    investments: {
      loadAllInvestments: 'Load all investments',
      noPayments: 'There are no investments to show yet',
      showFewerPayments: 'Show fewer payments',
      showAllPayments: 'Show all payments',
    },
    support: {
      sendMessage: 'Send',
      messageSent: 'Your message has been sent',
      youWillBeAnswered: 'We will answer you by email as soon as possible',
    },
    errors: {
      pdfError: 'There was an error with retrieving the document. Please try again later or contact support',
      noInvestmentsError: 'There are no investments for the selected year',
    },
  },
  settings: {
    details: {
      firstAndLastName: 'First and last name',
      changePassword: 'Change password',
      changeEmail: 'Change e-mail',
      newPasswordOptional: 'New password',
      newPasswordOptionalRepeat: 'Repeat new password',
      confirmWithCurrentPassword: 'Confirm with current password',
      leaveEmpty: 'Leave empty if you do not want to change your password',
      useAtLeastTen: 'Use at least 10 characters to keep your account secure',
      currentPassword: 'Current password',
      changeHere: 'This is the current IBAN number known to us, change if necessary and click on save',
      changeRegHere: 'This is the current  REGISTERATION number known to us, change if necessary and click on save',
      mailChanged: 'Your e-mail address has been changed. Please check your e-mail to activate your new e-mail address and login again',
      bankAccountChanged: 'Your bank account was correctly changed',
      passwordChanged: 'Your password has been changed. Please login again',
      nameUpdated: 'Your name has been updated',
      linkSent: 'Please, open the link we just sent to your e-mail in order to confirm the change on your bank account number',
      identity: 'Personal Information',
      id: 'Account ID',
    },
    identification: {
      identification: 'Identification',
      complete: 'Verify account',
      support: 'Support',
      title: 'Complete these steps to gain full access',
      userIdentification: 'Create an account',
      registeredToPlatform: 'You have created an account',
      investorIdentification: 'Verify your account',
      completeStep: 'Complete this step and start investing',
      success: 'Your account has been identified',
      rejected: 'Your identification request was rejected',
    },
  },
  help: {
    baseKnowledge: {
      rics: 'RICS',
      aex: 'AEX',
      afm: 'AFM',
    },
    faq: {
      faq: 'FAQs',
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the fund, the value of the property is divided pro rata among the shareholders at that time`,
      whenIsItSold: 'When is the real estate project sold / fund liquidated?',
      theAverage: 'The average duration of a fund is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the funds of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my shares?',
      sharesCanBe: 'Shares can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the fund is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the fund and real estate management?',
      thatIsBeingCared: 'The fund managers do this',
    },
  },
  checkout: {
    fund: {
      selectAnAmount: 'select an amount',
      fundDocs: 'fund docs',
      noFilesFound: 'no files found',
    },
    investment: {
      selectTheAmount: 'Select the amount of Immotokens to invest in.',
      investmentCosts: 'Investment costs',
      emissionCosts: 'Emission costs',
      totalCosts: 'Total cost',
      amountBelowZero: 'Oops. The amount is not a number or smaller than 0.',
      availableFund: 'The progress bar shows the availability of the fund',
      legalTerms: 'Legal Terms',
      finalize: 'And finalise the payment',
    },
    questionnaire: {
      questionnaire: 'Questionnaire',
      mandatory: 'All fields are mandatory',
    },
    legal: {
      accept: 'Accept Legal Terms',
      continue: 'And pay',
      legalAgreement: 'Registration form',
      paymentRecap: 'Payment Recap',
      finalisingPayment: 'You are finalising this payment',
    },
    status: {
      congratulations: 'Congratulations',
    },
    errors: {
      notEnoughPermissions: 'Not enough permissions with this credentials',
      somethingWentWrong: 'Whoops, something went wrong during the identification process',
      somethingIsMissing: 'Something is missing',
      somethingIsNotWorking: 'Something is not working',
      problemPersist: 'If the problem persist, please contact us for technical support',
      error404: 'Error 404 - Page not found',
      backToHomepage: 'Back to homepage',
    },
    redirect: {
      text: 'Generating payment...',
    },
  },
  identification: {
    privateInvestor: 'Private investor',
    selectAnOption: 'Select an option',
    noDutchBankAccount: 'Invest as private investor with non-Dutch bank account',
    businessTitle: 'Business investor',
    businessInvestment: 'Invest with your business',
    questionnaire: {
      intro: 'Before you verify your identity please answer these questions:',
      submit: 'Submit Answers',
      move: 'And move to identification',
    },
    business: {
      fillFieldsCompany: 'Fill the fields with your company info',
      kvkNumber: 'KVK number',
      uploadYourKvKImage: 'Upload your KvK statement',
      submitYourData: 'Submit your data',
      orContactUs: 'Contact us',
    },
    idin: {
      selectAnAmount: 'select an amount',
      identityVerification: 'Identity verification',
      pleaseVerify: 'Please verify your identity using',
      verificationService: 'a verification service provided by Dutch banks',
      completedIdin: 'We\'ve completed your iDIN verification',
      pleaseWait: 'Please wait while we connect to iDIN',
      pleaseSelectAnOption: 'Please, select an option',
      selectBank: 'Select your bank',
      thereWasAnError: 'There was an error with iDIN',
      pleaseContactSupport: 'Please contact support',
      whatIsIdin: 'iDIN explained',
      idinExplanation: `iDIN is a Dutch digital identity provider. Individuals can use iDIN to identify with a company such as {companyName}.
        A person logs in at his or her bank. The bank then passes on the data from the person, who has already been checked by the bank, to {companyName}.
        All customers of the bank that have been fully identified by the bank can use iDIN.`,
      idinError: 'There was an error with iDIN service, please try again or contact support',
    },
    world: {
      fillWithInfo: 'Fill the fields with your info',
      uploadYourPassport: 'Upload your identification document (passport, ID, residence permit, drivers license)',
      submitYourData: 'Submit your data',
    },
    FormStatus: {
      requestSent: 'Request sent!',
      weAreCheckingYourData: 'We are checking your personal information,<br>you will receive an e-mail within a few days with the next steps',
      youAreVerified: 'You are verified and able to invest!',
      exploreProperties: 'Explore properties',
      requestRejected: 'Request rejected!',
      weRejectedYourRequest: 'Your request was rejected, if you think this is an error please contact us',
    },
  },
  properties: {
    exploreProperty: 'More details',
    noResults: 'We could not find any property with this name, please try again',
  },
  property: {
    propertyInfo: {
      investOnThisFund: 'Invest in this fund',
    },
    propertyContent: {
      progress: 'Progress of the fund',
      investmentCase: 'Investment case',
      propertyDetails: 'Property details',
      additionalInfo: 'Additional info',
    },
    reservation: {
      title: 'Subscribe for updates',
      confirm: 'Please fill in the mandatory fields to show your interest in <strong>{name}</strong>.',
      amountToReserve: 'How much would you wanna invest in euros?',
      error: 'Something went wrong.',
      successTitle: 'Thanks for your reservation!',
      successBody: 'As soon as the property gets available we will update you via e-mail.',
    },
  },
  tooltip: {
    amountOwned: 'This is the total number of the Shares you hold',
    amountGet: 'This is the total amount of Euros you get every month / year',
    fundSize: 'Total value of the fund',
    availability: 'Available amount of shares',
    yourInvestment: 'The total amount you invested in this fund',
    emissionCosts: 'The fee applied to purchase a share',
    fundProgress: 'Percentage of the fund that has been sold',
    onlinePayment: 'This payment was made through the platform',
    offlinePayment: 'This payment was made outside of the platform',
    initialLogin: 'Click here to see your dashboard and investment overview',
  },
  gdpr: {
    modal: {
      headerTitle: 'Our Data Collection Policy',
      headerText: '{companyName} makes use of \'cookies\' on this page. A cookie is a little piece of information stored on your device. By that we are able to recognise you at your next visit.',
      headerTitle2: 'Why we use cookies',
      /* eslint-disable max-len */
      headerText2: 'We use cookies in order to ensure the functionality and security of our website. Further, this allows us to analyse and improve our site, products, and services. By these cookies we recognise you at the next visit. No phone numbers or e-mail addresses are saved in them. You allow us to use these cookies by saving your preferences by clicking \'save\'. \n\n We use different types of cookies\nIn particular four types of cookies: Functional Cookies, Analytics Cookies, Advertisment, and Personalisation cookies. Find below more details and the selection for the different types of cookies we use.',
      /* eslint-enable max-len */
      bodyButtons: 'Select which information services are activated',
      bodyButtonsDeselect: 'Deselect all',
      bodyButtonsSelect: 'Select all',
      bodySeeMore: 'See more',
      bodySeeLess: 'See less',
    },
  },
  footerExpanded: {
    aboutUs: 'About us',
    investors: 'investors',
    offer: 'offer',
    portfolio: 'portfolio',
    faq: 'faq',
    legal: 'legal',
    termsOfUse: 'terms of use',
    privacyStatement: 'privacy statement',
    cookiePolicy: 'cookie policy',
    contact: 'contact',
    address1: 'Engelhoekstraat 22B',
    address2: '9800 Sint-Martens-Leerne',
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Checkout',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Details',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Glossary',
    },
    identification: {
      title: 'Identification',
    },
    knowledge: {
      title: 'Knowledge base',
    },
    landing: {
      description: 'The description of the Landing Page',
    },
    login: {
      title: 'Login',
      description: 'Login to your account to invest in our properties.',
    },
    properties: {
      title: 'Funds',
      description: 'Browse our properties and find out more about them here.',
    },
    property: {
      description: 'Invest in {name} now! This property is located at {address}. You can start investing from {min} euros.',
    },
    register: {
      title: 'Register',
      description: 'Sign up for {name} and discover our properties. Register with your e-mail to start investing.',
    },
    reset: {
      title: 'Reset password',
    },
    settings: {
      title: 'Settings',
    },
    verification: {
      title: 'Verification',
    },
  },
};

export default {
  en: mergeWithBrandLangFile(original, 'en'),
};
